.bg-SectionExpertise {
  background-image: url("../images/homePage/card-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-SectionExpertise2 {
  background-image: url("../images/travelersBG.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.home-slider-buttons {
  .right-button {
    right: -20px;
    position: relative;
  }
  .left-button {
    left: -20px;
    position: relative;
  }
}

.bg-shadow1 {
  background: linear-gradient(
      138.34deg,
      #d2c3ee 2.7%,
      rgba(217, 217, 217, 0) 40.35%
    ),
    linear-gradient(214.42deg, #fce1d5 13.73%, rgba(217, 217, 217, 0) 40.42%);
  position: absolute;
  height: 1500px;
  width: 100%;
}
.boxshadow1 {
  box-shadow: 0px 8px 28px 0px #00000014;
}
.boxshadow2 {
  box-shadow: 0px 2px 16px 0px #0000000a;
}
.boxshadow3 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
// banner video media Queries
@media (max-width: 4000px) and (min-width: 2000px) {
  .banner-homevideo {
    height: 900px !important;
  }
}
@media (max-width: 1999px) and (min-width: 1536px) {
  .banner-homevideo {
    height: 800px !important;
  }
}
@media (max-width: 1535px) and (min-width: 1024px) {
  .banner-homevideo {
    height: 600px !important;
  }
}
@media (max-width: 1023px) and (min-width: 568px) {
  .banner-homevideo {
    height: 400px !important;
  }
}
@media (max-width: 567px) and (min-width: 320px) {
  .banner-homevideo {
    height: 300px !important;
  }
}
@import "./_tw_safe_classes";
@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates";
@import "./_ant_design";
@import "./_homepage";
@import "./_list_properties";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

// common css for all labels of antd
// homepage section video
.video-container {
  width: 100% !important;
  height: 100% !important;
  border-radius: 20px !important;
}
// video cover
video {
  object-fit: fill !important;
  // border-radius: 20px;
}
.video-container-home video {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

// placeholder
.image-container {
  height: 450px;
  width: 100%;
  animation: placeholder ease-in-out 2s infinite;
}
@keyframes placeholder {
  0% {
    background-color: #eeeeee;
  }
  50% {
    background-color: #cccccc;
  }
  100% {
    background-color: #eeeeee;
  }
}

//
@media (max-width: 1900px) and (min-width: 1800px) {
  .aboutus-list-container {
    margin-left: 25% !important;
    margin-right: 25% !important;
  }
}
@media (max-width: 2200px) and (min-width: 1901px) {
  .aboutus-list-container {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }
}
@media (max-width: 3500px) and (min-width: 2201px) {
  .aboutus-list-container {
    margin-left: 29% !important;
    margin-right: 29% !important;
  }
}

//
@media (max-width: 3000px) and (min-width: 1024px) {
  .about-gift-img img {
    height: 120%;
    margin-top: -25px;
  }
}

.shadow-listings-box {
  box-shadow: 0px 9px 24px -8px #00000026;
}
textarea:focus,
select:focus,
input:focus {
  border-color: #612ace !important;
}
option:focus {
  border-color: #612ace !important;
  background-color: #612ace !important;
}
option:hover {
  background-color: #612ace !important ;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
// service section in Media Queries
// banner video media Queries
@media (max-width: 4000px) and (min-width: 2000px) {
  .banner-servicevideo {
    height: 900px !important;
  }
}
@media (max-width: 1999px) and (min-width: 1536px) {
  .banner-servicevideo {
    height: 800px !important;
  }
}
@media (max-width: 1535px) and (min-width: 1024px) {
  .banner-servicevideo {
    height: 600px !important;
  }
}
@media (max-width: 1023px) and (min-width: 568px) {
  .banner-servicevideo {
    height: 400px !important;
  }
}
@media (max-width: 567px) and (min-width: 320px) {
  .banner-servicevideo {
    height: 300px !important;
  }
}
